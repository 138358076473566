<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    v-bind="$attrs"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        v-model="date"
        :label="label"
        :placeholder="placeholder"
        v-bind="$attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      :active-picker.sync="activePicker"
      :max="
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      "
      min="1900-01-01"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import store from '@/store'
export default {
  props: {
    label: {
      type: String,
      default: 'Date of Expiry'
    },
    placeholder: {
      type: String,
      default: 'Date of Expiry'
    }
  },
  data() {
    return {
      menu: false,
      activePicker: null
    }
  },
  computed: {
    date: {
      get: () => {
        return store.state.voucher.voucherForm.date_expiry
      },
      set: () => {}
    }
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
      this.date = date
      this.$emit('selectDate', date)
    }
  },
  watch: {
    date(val) {},
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  }
}
</script>
