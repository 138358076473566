<template>
  <div>
    <v-form
      @submit.prevent="add"
      :disabled="form.$busy"
      ref="form"
      v-if="form.code"
    >
      <v-row v-if="form.id && form.status === 'CLAIMED'">
        <v-col cols="12">
          <v-alert variant="outlined" type="warning" prominent border="top">
            Note: You cannot delete or modify claimed voucher
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            required
            label="Code"
            hide-details="auto"
            v-model="form.code"
            :disabled="true"
            :error-messages="form.$getError('code')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <SelectUser
            v-model="form.patient_id"
            :error-messages="form.$getError('patient_id')"
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <SelectDate
            v-model="form.date_expiry"
            @selectDate="onSelectedDate"
            :error-messages="form.$getError('date_expiry')"
            readonly
          />
        </v-col>
      </v-row>

      <v-row v-if="form.id">
        <v-col cols="12">
          <v-select
            v-model="form.status"
            label="Status"
            outlined
            :items="computedItems"
            item-disabled="disabled"
            :error-messages="form.$getError('status')"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-divider></v-divider>
        <v-col cols="12">
          <v-btn @click="onCancel" text>Cancel</v-btn>
          <v-btn
            type="submit"
            color="primary"
            :disabled="isDisabledSubmit"
            :loading="form.$busy"
            >{{ submitLabel }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Voucher from '@/models/Voucher'
import { mapState } from 'vuex'
import SelectUser from '@/components/SelectUser.vue'
import SelectDate from '@/components/SelectDate.vue'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SelectUser,
    SelectDate
  },
  computed: {
    ...mapState({
      form: state => state.voucher.voucherForm
    }),
    computedItems() {
      return this.statusItems.map(item => {
        return {
          text: item,
          disabled: this.disableStatusItems.includes(item)
        }
      })
    },
    submitLabel() {
      return this.form.id ? 'Update' : 'Submit'
    },
    isDisabledSubmit() {
      return (
        this.form.id &&
        (this.form.status === 'CLAIMED' || this.form.status === 'EXPIRED')
      )
    }
  },
  data() {
    return {
      statusItems: ['PENDING', 'CLAIMED', 'EXPIRED', 'REVOKED'],
      disableStatusItems: ['CLAIMED', 'EXPIRED'],
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },
  methods: {
    async add() {
      this.form.$busy = true
      this.form.$clearErrors()
      let formData = this.form.$data()
      if (formData.id) {
        formData.id = formData.code
      }
      await new Voucher(formData)
        .save()
        .then(res => {
          if (formData.id) {
            this.showSnackbar('Voucher successfully updated!', 'success')
          } else {
            this.$router.replace(
              !formData.id ? `/vouchers/${res.code}` : '/vouchers'
            )
          }
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    onCancel() {
      this.dialog = false
      this.form.$reset()
      if (this.form.id) {
        this.$router.push({ name: 'vouchers' })
      } else {
        this.$emit('closeDialog')
      }
    },

    onSelectedDate(date) {
      this.form.date_expiry = date
    }
  }
}
</script>
