<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-card>
        <v-card-title>{{ headerLabel }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <VoucherForm @closeDialog="closeDialog" />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import VoucherForm from './VoucherForm.vue'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  components: {
    VoucherForm
  },
  computed: {
    ...mapState({
      voucherDetails: state => state.voucher.voucherDetails,
      voucherGeneratedCode: state => state.voucher.voucherGeneratedCode
    }),
    headerLabel() {
      return `Add Voucher`
    }
  },
  data() {
    return {
      dialog: this.value
    }
  },
  mounted() {},
  methods: {
    ...mapMutations({
      clearForm: 'voucher/clearVoucherForm',
      setCodeForm: 'voucher/setCodeForm',
      setDefaultUserId: 'user/setDefaultUserId',
      clearUserList: 'user/clearUserList'
    }),
    closeDialog() {
      this.dialog = false
    }
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      if (val) {
        this.clearForm()
        this.setCodeForm(this.voucherGeneratedCode)
        this.setDefaultUserId(null)
        this.clearUserList()
      }
      this.dialog = val
    }
  }
}
</script>
