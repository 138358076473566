<template>
  <div>
    <v-hover v-slot="{ hover }">
      <v-list-item :two-line="value.status == 'disabled' || value.status == 0">
        <v-list-item-avatar>
          <v-img :src="thumbSrc(value.image)"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ value.name }}</v-list-item-title>
          <v-list-item-subtitle
            v-if="value.status == 'disabled' || value.status == 0"
            ><v-chip color="error" x-small>
              Disabled
            </v-chip></v-list-item-subtitle
          >
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            :loading="loading"
            :class="{ 'visibility-hidden': !hover }"
            @click="deleteItem(value.pivot.id)"
            icon
          >
            <v-icon color="red lighten-3">{{ icons.delete }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-hover>
    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mdiTrashCanOutline } from '@mdi/js'

export default {
  props: {
    value: {
      type: Object
    },
    week: {
      type: Number
    },
    type: {
      type: String
    }
  },

  data() {
    return {
      loading: false,
      icons: {
        delete: mdiTrashCanOutline
      },
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    async deleteItem(id) {
      this.loading = true
      this.$api
        .delete(`plan-weeks/${this.week}/${this.type}?id=${id}`)
        .then(res => {
          this.$store.commit('plan/setPlanDetails', res.data.data)
          this.showSnackbar('Meal successfully removed!', 'success')
        })
        .finally(() => (this.loading = false))
    },

    thumbSrc(image) {
      return image
        ? image.thumb_url
        : require('@/assets/images/default-image.jpeg')
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  }
}
</script>

<style scoped>
.visibility-hidden {
  opacity: 0.2;
}
</style>
