<template>
  <div>
    <v-autocomplete
      outlined
      v-model="defaultUserId"
      :label="label"
      :item-text="textColumn"
      :item-value="valueColumn"
      :placeholder="placeholder"
      :items="users"
      :search-input.sync="searchInput"
      :loading="loading"
      v-bind="$attrs"
      auto-select-first
      @keyup="search(searchInput)"
      @input="onChange"
    ></v-autocomplete>
  </div>
</template>

<script>
import SETTINGS from '@/utils/enums/Settings'
import { debounce } from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import store from '@/store'

export default {
  props: {
    value: {
      type: [String, Number],
      default: null
    },
    label: {
      type: String,
      default: 'Patient'
    },
    placeholder: {
      type: String,
      default: 'Search Patient'
    },

    valueColumn: {
      type: String,
      default: 'id'
    },

    textColumn: {
      type: String,
      default: 'full_name'
    }
  },
  data() {
    return {
      loading: false,
      searchInput: null,
      items: [{ id: 13, full_name: 'remz' }]
    }
  },
  mounted() {
    // this.search()
  },
  deactivated() {},
  computed: {
    ...mapState({
      users: state => state.user.list
    }),
    defaultUserId: {
      get: () => {
        return store.state.user.defaultUserId
      },
      set: () => {}
    }
  },
  methods: {
    ...mapActions({
      getUsers: 'user/getUsers'
    }),
    ...mapMutations({
      clearUsers: 'user/clearUserList'
    }),
    search: debounce(function(value) {
      this.fetch(value)
    }, SETTINGS.search.intervalPerSearch),
    async fetch(search = null) {
      this.loading = true
      await this.getUsers({
        search: search,
        filters: [],
        page: 1,
        itemsPerPage: 50,
        sortBy: []
      })
      this.loading = false
    },
    onChange(value) {
      this.$emit('input', value)
    }
  }
}
</script>
