<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="addExercise" ref="form">
        <v-card>
          <v-card-title>Add Exercise</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    outlined
                    label="Category"
                    hide-details="auto"
                    :items="type_items"
                    persistent-placeholder
                    v-model="form.category"
                    :error-messages="form.$getError('category')"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :items="exercises"
                    :loading="exercisesLoading"
                    item-text="name"
                    item-value="id"
                    label="Exercises"
                    multiple
                    chips
                    outlined
                    required
                    class="custom-autocomplete"
                    hide-details="auto"
                    persistent-placeholder
                    v-model="form.exercises"
                    :error-messages="form.$getError('exercises')"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                        close
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    week: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      dialog: this.value,
      exercises: null,
      exercisesLoading: true,
      form: new Form({
        exercises: null,
        category: 'light'
      }),
      type_items: [
        { text: 'Light', value: 'light' },
        { text: 'Moderate', value: 'moderate' },
        { text: 'Vigorous', value: 'vigorous' }
      ],
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  created() {
    this.getExerciseOptions()
  },

  methods: {
    ...mapActions({
      addExercisePlanWeekAction: 'plan/addExercisePlanWeek'
    }),

    addExercise() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.$api
        .post(`plan-weeks/${this.week}/exercises`, this.form.$data())
        .then(res => {
          this.$store.commit('plan/setPlanDetails', res.data.data)
          this.closeDialog()
          this.showSnackbar('Exercise successfully added!', 'success')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    async getExerciseOptions() {
      this.$api
        .get(`exercises?limit=999999`)
        .then(res => {
          this.exercises = res.data.data
        })
        .finally(() => (this.exercisesLoading = false))
    },

    closeDialog() {
      this.dialog = false
      this.form.meals = null
      this.form.type = 'light'
      this.form.$clearErrors()
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
    }
  }
}
</script>

<style>
.custom-autocomplete .v-select__selections {
  min-height: 0 !important;
}
</style>
