<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'plans' }"
          >Plans</router-link
        >
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <template v-if="plan && plan.category == 'unassigned'">
        <v-btn @click="deleteConfirmDialog = true" color="error" text dark>
          <v-icon class="mr-3">
            {{ icons.delete }}
          </v-icon>
          Delete Plan
        </v-btn>
      </template>
    </v-app-bar>

    <v-divider></v-divider>

    <div class="pa-8" v-if="form">
      <v-form @submit.prevent="updatePlan" ref="form">
        <v-row>
          <v-col md="4">
            <v-text-field
              outlined
              required
              label="Name *"
              hide-details="auto"
              v-model="form.name"
              :error-messages="form.$getError('name')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4">
            <v-select
              :items="plan_type_items"
              label="Type"
              outlined
              hide-details="auto"
              v-model="form.category"
              :error-messages="form.$getError('category')"
            ></v-select>
          </v-col>
        </v-row>
        <div class="my-5">
          <v-btn
            type="submit"
            color="primary"
            width="150px"
            :loading="form.$busy"
            depressed
          >
            Save
          </v-btn>
        </div>
      </v-form>

      <v-divider></v-divider>

      <div class="d-flex justify-space-between align-center my-8">
        <h2 class="mr-auto">Week Plan</h2>
        <v-btn
          @click="addWeek"
          :loading="addWeekLoading"
          color="primary"
          width="150px"
          depressed
        >
          Add Week
        </v-btn>
      </div>

      <div class="px-3">
        <v-row justify="center">
          <v-expansion-panels multiple accordion>
            <v-expansion-panel v-for="(week, index) in plan.weeks" :key="index">
              <v-expansion-panel-header
                >Week {{ week.week_no }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card elevation="0" outlined>
                      <v-app-bar elevation="0">
                        <v-toolbar-title class="text-h6 pl-0">
                          Meals
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn @click="showAddMealDialog(week.id)" icon>
                          <v-icon>{{ icons.add }}</v-icon>
                        </v-btn>
                      </v-app-bar>
                      <v-list class="pt-0">
                        <div
                          v-for="(meal_type, index) in meal_types"
                          :key="index"
                        >
                          <v-divider></v-divider>
                          <v-subheader class="text-capitalize">{{
                            meal_type
                          }}</v-subheader>
                          <div
                            v-for="(meal, index) in filterBy(
                              week.meals,
                              meal_type
                            )"
                            :key="index"
                          >
                            <PlanListItem
                              :value="meal"
                              :week="week.id"
                              type="meals"
                            ></PlanListItem>
                          </div>
                          <div
                            v-if="filterBy(week.meals, meal_type).length === 0"
                            class="text--secondary text-caption text-center"
                          >
                            No meals attached.
                          </div>
                        </div>
                      </v-list>
                    </v-card>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card elevation="0" outlined>
                      <v-app-bar elevation="0">
                        <v-toolbar-title class="text-h6 pl-0">
                          Exercises (required for
                          {{ week.exercise_required_days }} days)
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn @click="showEditPlanWeekDialog(week)" icon>
                          <v-icon>{{ icons.edit }}</v-icon>
                        </v-btn>
                        <v-btn @click="showAddExerciseDialog(week.id)" icon>
                          <v-icon>{{ icons.add }}</v-icon>
                        </v-btn>
                      </v-app-bar>
                      <v-list class="pa-0">
                        <div
                          v-for="(exercise_intensity,
                          index) in exercise_intensities"
                          :key="index"
                        >
                          <v-divider></v-divider>
                          <v-subheader class="text-capitalize">{{
                            exercise_intensity
                          }}</v-subheader>
                          <div
                            v-for="(exercise, index) in filterBy(
                              week.exercises,
                              exercise_intensity
                            )"
                            :key="index"
                          >
                            <PlanListItem
                              :value="exercise"
                              :week="week.id"
                              type="exercises"
                            ></PlanListItem>
                          </div>
                          <div
                            v-if="
                              filterBy(week.exercises, exercise_intensity)
                                .length === 0
                            "
                            class="text--secondary text-caption text-center"
                          >
                            No exercises attached.
                          </div>
                        </div>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
                <div
                  class="mt-5"
                  v-if="index + 1 === plan.weeks.length && index !== 0"
                >
                  <v-btn
                    @click="deleteWeek(week.id)"
                    :loading="deleteWeekLoading"
                    color="error"
                    width="150px"
                    depressed
                  >
                    Delete Week
                  </v-btn>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </div>
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <PlanAddMealDialog
      v-model="addMealDialog"
      :week="addWeekNo"
    ></PlanAddMealDialog>

    <PlanAddExerciseDialog
      v-model="addExerciseDialog"
      :week="addWeekNo"
    ></PlanAddExerciseDialog>

    <EditPlanWeekExerciseDialog
      v-model="editPlanWeekDialog"
      :planWeek="editWeek"
    ></EditPlanWeekExerciseDialog>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete plan"
      message="Are you sure you want to delete this plan?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deletePlan"
    ></ConfirmModal>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiChevronLeft, mdiTrashCan, mdiPlus, mdiPencilOutline } from '@mdi/js'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mapActions, mapMutations, mapState } from 'vuex'
import PlanListItem from './components/PlanListItem.vue'
import PlanAddMealDialog from './components/PlanAddMealDialog'
import PlanAddExerciseDialog from './components/PlanAddExerciseDialog'
import EditPlanWeekExerciseDialog from './components/EditPlanWeekExerciseDialog'
import Form from '@/utils/form'

export default {
  name: 'PlanDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
    PlanListItem,
    PlanAddMealDialog,
    PlanAddExerciseDialog,
    EditPlanWeekExerciseDialog
  },

  data() {
    return {
      addExerciseDialog: false,
      addMealDialog: false,
      editPlanWeekDialog: false,
      addWeekLoading: false,
      deleteWeekLoading: false,
      addWeekNo: 0,
      editWeek: null,
      icons: {
        arrowLeft: mdiChevronLeft,
        delete: mdiTrashCan,
        add: mdiPlus,
        edit: mdiPencilOutline
      },
      form: null,
      loading: false,
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      deleteConfirmDialog: false,
      plan_type_items: [
        { text: 'Free', value: 'free' },
        { text: 'Premium', value: 'premium' },
        { text: 'Unassigned', value: 'unassigned' }
      ],
      meal_types: ['breakfast', 'lunch', 'dinner', 'snack'],
      exercise_intensities: ['light', 'moderate', 'vigorous']
    }
  },

  computed: {
    ...mapState({
      plan: state => state.plan.planDetails
    })
  },

  methods: {
    ...mapActions({
      getPlanDetails: 'plan/getPlanDetails',
      updatePlanDetails: 'plan/updatePlanDetails',
      deletePlanAction: 'plan/deletePlan'
    }),

    ...mapMutations({
      clearPlanDetails: 'plan/clearPlanDetails'
    }),

    async getPlan() {
      await this.getPlanDetails(this.$route.params.id)
      this.form = new Form(this.plan)
    },

    updatePlan() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.updatePlanDetails(this.form.$data())
        .then(() => {
          this.showSnackbar('Plan details successfully updated!', 'success')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    deletePlan() {
      this.deletePlanAction(this.$route.params.id)
      this.$router.push({ name: 'plans' })
    },

    addWeek() {
      this.addWeekLoading = true
      this.$api
        .post(`plan-weeks`, { plan_id: this.plan.id })
        .then(res => {
          this.$store.commit('plan/setPlanDetails', res.data.data)
          this.showSnackbar('Week successfully added!', 'success')
        })
        .finally(() => (this.addWeekLoading = false))
    },

    deleteWeek(id) {
      this.deleteWeekLoading = true
      this.$api
        .delete(`plan-weeks/${id}`)
        .then(res => {
          this.$store.commit('plan/setPlanDetails', res.data.data)
          this.showSnackbar('Week successfully deleted!', 'success')
        })
        .finally(() => (this.deleteWeekLoading = false))
    },

    thumbSrc(image) {
      return image || require('@/assets/images/default-image.jpeg')
    },

    showAddMealDialog(week) {
      this.addMealDialog = true
      this.addWeekNo = week
    },

    showAddExerciseDialog(week) {
      this.addExerciseDialog = true
      this.addWeekNo = week
    },

    showEditPlanWeekDialog(week) {
      this.editPlanWeekDialog = true
      this.editWeek = week
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    filterBy(data, category) {
      return data.filter(data => {
        return data.pivot.category === category
      })
    }
  },

  created() {
    this.getPlan()
  },

  destroyed() {
    this.clearPlanDetails()
  },

  watch: {
    $route() {
      this.getMealRestriction()
    }
  }
}
</script>
