<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Vouchers ({{ listMeta.total }})</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn
        color="primary"
        class="mb-2"
        :loading="loadingGenerateCode"
        @click="onGenerateCode"
      >
        <v-icon left> {{ icons.add }} </v-icon>Add Voucher
      </v-btn>
    </v-app-bar>

    <div class="mt-12 mb-6">
      <v-row>
        <v-col cols="6" md="3">
          <v-text-field
            outlined
            clearable
            hide-details
            label="Search"
            @input="doFilter"
            placeholder="Search by Name"
            v-model="search"
          ></v-text-field>
        </v-col>
      </v-row>
    </div>

    <v-data-table
      :items="vouchers"
      :loading="loading"
      :headers="headers"
      class="elevation-1"
      :options.sync="options"
      :server-items-length="listMeta.total"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
    >
      <template v-slot:top="{ pagination, options, updateOptions }">
        <v-data-footer
          :pagination="pagination"
          :options="options"
          @update:options="updateOptions"
          items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          :items-per-page-options="[10, 20, 50, 100]"
        />
      </template>
      <template v-slot:[`item.category`]="{ item }">
        <span class="text-capitalize">{{ item.category }}</span>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.addedDate }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          block
          depressed
          @click="
            $router.push({
              name: 'vouchers.details',
              params: { id: item.code }
            })
          "
        >
          Edit
        </v-btn>
      </template>
    </v-data-table>

    <VoucherDialog v-model="addVoucherDialog" />
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ControlsMixin from '@/utils/mixins/Controls'
import { mdiPlus } from '@mdi/js'
import { mapState, mapActions, mapMutations } from 'vuex'
import debounce from 'lodash/debounce'
import VoucherDialog from './components/VoucherDialog.vue'

export default {
  name: 'VouchersPage',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    VoucherDialog
  },
  data() {
    return {
      addVoucherDialog: false,
      icons: {
        add: mdiPlus
      },
      loading: false,
      loadingGenerateCode: false,
      options: {},
      headers: [
        {
          text: 'Code',
          sortable: true,
          value: 'code'
        },
        {
          text: 'Patient',
          sortable: true,
          value: 'patientName',
          width: '200px',
          align: 'center'
        },
        {
          text: 'Date Expiry',
          sortable: false,
          value: 'dateExpiry',
          width: '200px'
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          width: '200px'
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
          value: 'actions',
          width: '150px'
        }
      ],
      search: null
    }
  },
  computed: {
    ...mapState({
      vouchers: state => state.voucher.list,
      listMeta: state => state.voucher.listMeta
    })
  },
  methods: {
    ...mapActions({
      getVouchers: 'voucher/getVouchers',
      generateCode: 'voucher/generateCode'
    }),

    ...mapMutations({
      clearVouchers: 'voucher/clearVoucherList'
    }),

    async onGenerateCode() {
      this.loadingGenerateCode = true
      await this.generateCode()
      this.addVoucherDialog = true
      this.loadingGenerateCode = false
    },

    async getDataFromApi() {
      this.loading = true
      this.clearVouchers()
      await this.getVouchers({
        search: this.search,
        ...this.options
      })
      this.loading = false
    },

    doFilter: debounce(function() {
      this.getDataFromApi()
    }, 600)
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    }
  }
}
</script>
