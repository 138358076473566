<template>
  <div>
    <v-dialog v-model="dialog" max-width="600" persistent scrollable>
      <v-form @submit.prevent="updatePlanWeek" ref="form">
        <v-card>
          <v-card-title>Update Plan Week</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    required
                    label="Required days to do exercises *"
                    hide-details="auto"
                    v-model="form.exercise_required_days"
                    :error-messages="form.$getError('exercise_required_days')"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <slot>
              <v-btn @click="closeDialog" text>Cancel</v-btn>
              <v-btn type="submit" color="primary" :loading="form.$busy"
                >Submit</v-btn
              >
            </slot>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Form from '@/utils/form'

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    planWeek: {
      type: Object
    }
  },

  data() {
    return {
      dialog: this.value,
      form: new Form(),
      snackbar: {
        show: false,
        message: null,
        color: ''
      }
    }
  },

  methods: {
    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
      this.form.$clearErrors()
    },

    updatePlanWeek() {
      this.form.$busy = true
      this.form.$clearErrors()
      this.$api
        .put(`plan-weeks/${this.planWeek.id}`, this.form.$data())
        .then(res => {
          this.$store.commit('plan/setPlanDetails', res.data.data)
          this.closeDialog()
          this.showSnackbar('Plan Week Exercise Updated!', 'success')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.form.$setErrors(err.response.data.errors)
          }
        })
        .finally(() => (this.form.$busy = false))
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    }
  },

  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.dialog = val
      if (val)
        this.form = new Form({
          plan_id: this.planWeek.plan_id,
          exercise_required_days: this.planWeek.exercise_required_days
        })
    }
  }
}
</script>
