const SETTINGS = {
  search: {
    intervalPerSearch: 600
  },
  pagination: {
    itemsPerPage: 10,
    totalVisiblePageNumber: 6
  }
}

export default SETTINGS
