<template>
  <div class="py-8">
    <v-app-bar flat color="transparent">
      <app-bar-nav-icon />
      <v-toolbar-title class="breadcrumbs d-flex align-center">
        <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
        <router-link class="text-decoration--none" :to="{ name: 'vouchers' }"
          >Vouchers</router-link
        >
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <template>
        <v-btn @click="deleteConfirmDialog = true" color="error" text dark>
          <v-icon class="mr-3">
            {{ icons.delete }}
          </v-icon>
          Delete Voucher
        </v-btn>
      </template>
    </v-app-bar>

    <v-divider></v-divider>

    <div class="pa-8" v-if="form">
      <VoucherForm />
    </div>

    <v-snackbar v-model="snackbar.show" right :color="snackbar.color">
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text @click="snackbar.show = false" v-bind="attrs">OK</v-btn>
      </template>
    </v-snackbar>

    <ConfirmModal
      v-model="deleteConfirmDialog"
      title="Delete Voucher"
      message="Are you sure you want to delete this voucher?"
      @cancel="deleteConfirmDialog = false"
      @confirm="deleteVoucher"
    ></ConfirmModal>
  </div>
</template>

<script>
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import { mdiChevronLeft, mdiTrashCan, mdiPlus, mdiPencilOutline } from '@mdi/js'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mapActions, mapMutations, mapState } from 'vuex'
// import Form from '@/utils/form'
import VoucherForm from './components/VoucherForm.vue'

export default {
  name: 'VoucherDetails',

  components: {
    AppBarNavIcon,
    ConfirmModal,
    VoucherForm
  },

  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
        delete: mdiTrashCan,
        add: mdiPlus,
        edit: mdiPencilOutline
      },
      loading: false,
      snackbar: {
        show: false,
        message: null,
        color: ''
      },
      deleteConfirmDialog: false
    }
  },

  computed: {
    ...mapState({
      form: state => state.voucher.voucherForm
    })
  },

  methods: {
    ...mapActions({
      getVoucherDetails: 'voucher/getVoucherDetails',
      deleteVoucherAction: 'voucher/deleteVoucher'
    }),

    ...mapMutations({}),

    async getVoucher() {
      this.form.$busy = true
      await this.getVoucherDetails(this.$route.params.id)
      this.form.$busy = false
    },

    deleteVoucher() {
      this.deleteVoucherAction(this.$route.params.id)
      this.$router.push({ name: 'vouchers' })
    },

    showSnackbar(message, color) {
      this.snackbar.message = message
      this.snackbar.show = true
      this.snackbar.color = color
    },

    filterBy(data, category) {
      return data.filter(data => {
        return data.pivot.category === category
      })
    }
  },

  created() {
    this.getVoucher()
  },

  destroyed() {},

  watch: {}
}
</script>
